import { classifyService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { ClassifyResource, ClassifyList } from '@/resource/model';
import { RegionTreeResource } from '@/resource/model/public-data/region';

import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { SelectMaterialClassify } from '@/views/dialogs';

@Component({
  name: 'AddMaterialClassification',
  components: { SelectMaterialClassify }
})
export default class AddMaterialClassification extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: ClassifyResource | null;
  @Prop({
    required: true,
    default: () => {
      return undefined;
    }
  })
  public parentData!: RegionTreeResource;

  public categoryParentDialogVisible: boolean = false;
  public valueType: string = '';

  public form: ClassifyResource = {
    id: undefined,
    parentId: null,
    parentName: '',
    categoryCode: '',
    categoryName: '',
    remark: ''
  };

  public resourceFormRules = {
    categoryCode: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          classifyService
            .checkCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch((error: any) => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    categoryName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
      // {
      //   validator: (rule: any, value: string, callback: Function): void => {
      //     storeLocationService
      //       .checkName(value, this.position?.id)
      //       .then((isRepeat: boolean) => {
      //         if (isRepeat) {
      //           callback(translation('requestError.regionNameRepeat'));
      //           return;
      //         }
      //         callback();
      //       })
      //       .catch(error => {
      //         callback(error);
      //       });
      //   },
      //   trigger: 'blur'
      // }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.title = 'materialClassification.title';
    this.form.id = undefined;
    if (this.parentData.id === 0) {
      this.form.parentName = '';
      this.form.parentId = this.parentData.id;
    } else {
      this.form.parentName = this.parentData.name;
      this.form.parentId = this.parentData.id;
    }
    if (this.position) {
      this.operationType = 'edit';
      this.$nextTick(() => {
        Object.assign(this.form, this.position);
      });

      return;
    }
    this.operationType = 'add';
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public openSelectDialog(value: string): void {
    this.categoryParentDialogVisible = true;
    this.valueType = value;
  }
  public handleSelected(row: ClassifyList): void {
    console.log('选中数据', row);
    this.form.parentName = row.categoryName;
    this.form.parentId = row.id;
  }

  public onSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await classifyService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await classifyService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
