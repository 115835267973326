import { render, staticRenderFns } from "./material-classification.vue?vue&type=template&id=fe93dbb0&scoped=true&"
import script from "./material-classification.ts?vue&type=script&lang=ts&"
export * from "./material-classification.ts?vue&type=script&lang=ts&"
import style0 from "./material-classification.scoped.scss?vue&type=style&index=0&id=fe93dbb0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe93dbb0",
  null
  
)

export default component.exports